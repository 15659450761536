import React, { useEffect, useState } from "react";
import Arrow from "../img/arrow.svg"
import "../scss/modail.scss"
import { ButtonCost } from "./button";




export function ModailCost(){
    const [act, stateAct] = useState(false)
    const [id, stateId] = useState(false)

    useEffect(()=>{
        const location = document.location.search
        const idPoster = location.split("=")[1]
        console.log(idPoster)

        if(idPoster === "1"){
            stateId(false)
        }else if(idPoster === "2"){
            stateId(true)
        }else{
            stateId(false)
        }
    
    },[])


    return(
        
        <div className={act? "cost cost__act":"cost"}>
            <div className="cost_title">
                <span>Ukraine - nation of heroes</span>
            </div>
            <div className={act? "cost_button cost_button__act":"cost_button"}>
                <ButtonCost id="5" href={id? "":"https://donate.stripe.com/bIYaGO4vy7or2pq9BY"}></ButtonCost>
                <ButtonCost id="25" href={id? "":"https://donate.stripe.com/00g8yG1jm4cf2pq5lh"}></ButtonCost>
                <ButtonCost id="50" href={id? "":"https://donate.stripe.com/cN202afac2470hiaFC"}></ButtonCost>
                <ButtonCost id="100" href={id? "":"https://donate.stripe.com/aEU4iqe687orgggeVT"}></ButtonCost>
                <ButtonCost id="500" href={id? "":"https://donate.stripe.com/14kcOW7HKcILd44aFE"}></ButtonCost>
                {/* <ButtonCost id="1000" href={id? "":"https://donate.stripe.com/7sIaGOd24bEH2pq8xy"}></ButtonCost> */}
            </div>
            <div className="cost_less" onClick={()=>{stateAct(!act)}}>
                <img src={Arrow} alt="" className={act? "arrow arrow__act":"arrow"}/>
                <span>{act? "Load less": "Load more"}</span>
            </div>
        </div>
    )
}
