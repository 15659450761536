import React, { useEffect, useState } from "react";
import "../scss/content.scss"
import {ModailCost} from "../jsx/modail"
import Girl from "../img/Ресурс 3-8.png"
import Logo from "../img/logo.png"
import LogoB from "../img/bodro.png"






export function Content(){
    const [iW,setIW] = useState(window.innerWidth)
    useEffect(()=>{
        window.onresize = ()=>{
            setIW(window.innerWidth)
        }
    })

    return(
        <div className="content">
            <div className="case-logo">
                <img src={LogoB} alt=""  className="logoB"/>
                <img src={Logo} alt="" className={iW>850? "logo": "logo logo__mini"}/>
            </div>
            <div className="content_midle">
                
                {
                    iW>850? <img className="img-screen" src={Girl} alt="img" />:""
                }
                <div className="case">
                    <span>Help <br/> Ukrainian warriors <br/>withstand</span>
                    <ModailCost></ModailCost>
                    {
                        iW>850? "":<img className="img-screen" src={Girl} alt="img" />
                    }
                    {/* <span>Save <br />the world<br /> tomorrow</span> */}
                </div>
            </div>
        </div>
    )
}

export function Footer(){
    return(
        <footer className="footer">
            <span className="footer_title"></span>
            <div className="dop-info">
                {/* <span className="dop-info_title">ФОНД КОМПЕНСУЄ ВАРТІСТЬ ЛІКІВ</span><br /> */}
                <span className="dop-info_sub-title">The collected funds will be sent to help rehabilitate Ukrainian military who were injured due to the aggression of the Russian Federation.</span><br />
                <span className="dop-info_info">How does the program work?</span>
                <ul className="dop-info_list">
                    <li>We will provide an opportunity for every injured person in need of recovery to receive free assistance through your donations and we will continue to do so on an ongoing basis.</li>
                    <li>The funds raised from donors around the world are used to purchase special equipment for the rehabilitation of wounded servicemen in Ukraine. These purchases will be made at special charity prices from international suppliers</li>
                    <li>Leading specialists from different clinics around the world will be involved in the rehabilitation plan in complicated medical cases.</li>
                    <li>We work under the supervision of the partner educational foundation "Ukrainian Educational Foundation" and ambassadors who help in the implementation of this important project.</li>
                </ul>
            </div>
            <span className="footer_sub-title">©2022 , CO "CF Ukrainian Educational Foundation", Biedrība (BDR) "JK NAMS". All rights reserved.
</span>
        </footer>
    )
}